/* eslint-disable react/no-danger */
import type { GatsbySSR } from "gatsby";
import React from "react";
import { GatsbyBrowser, Script, ScriptStrategy } from "gatsby";
import { BaseLayout } from "./src/components/BaseLayout";
import { PageProvider } from "./src/context/pageContext";
import { BrochurewareDataLayerWrapper } from "./src/components/BrochurewareDataLayerWrapper";
import { SitewideBannerProps } from "@zempler/theme/src/components/sitewideBanner/SitewideBanner";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  const pageID = props?.data?.page?.remoteId;
  const pageUrl = props?.data?.page?.url;
  const sitewidebanner = props?.data?.page
    ?.pageSitewideBanner as SitewideBannerProps;
  const hideNavigation = props?.data?.page?.hideNavigation;

  return (
    <BrochurewareDataLayerWrapper
      data={props.data}
      location={props.location}
      pageContext={props.pageContext}
    >
      <PageProvider
        pageID={pageID}
        pageUrl={pageUrl}
        sitewideBanner={sitewidebanner}
        hideNavigation={hideNavigation}
      >
        <BaseLayout>{element}</BaseLayout>
      </PageProvider>
    </BrochurewareDataLayerWrapper>
  );
};
