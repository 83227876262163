import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Header as HeaderComponent } from '@zempler/theme/src/components/header/Header';
import {
  ChildNavItem,
  NavItemProps,
} from '@zempler/theme/src/components/navigation/Navigation';
import { useContext } from 'react';
import { PageContext } from '../context/pageContext';
import SitewideBanner from './SitewideBanner';

const Header = () => {
  const { hideNavigation, sitewideBanner: pageSettings  } = useContext(PageContext)!;

  const data = useStaticQuery(graphql`
    query {
      umbracoSitewideBannerType {
        backgroundColour
        icon
        heading
        description
        bannerId
        enabled
      }
      umbracoHeaderType {
        ctaButton: button {
          ...Link
        }
        navigationItems {
          block {
            components {
              ... on UMBRACO_NavigationItemComponentType {
                link {
                  ...Link
                }
                headerChildItems {
                  link {
                    ...Link
                  }
                  textField
                  navigationChildItems {
                    link {
                      ...Link
                    }
                  }
                }
                ctaButton {
                  ...Link
                }
              }
            }
          }
        }
      }
    }
  `);

  const ctaButton = data.umbracoHeaderType?.ctaButton;

  const navItems =
    data.umbracoHeaderType?.navigationItems[0].block.components.map((item) => {
      const navItem: NavItemProps = {
        link: item.link,
        childItems: item.headerChildItems?.map((child) => {
          const childNavItem: ChildNavItem = {
            link: child.link,
            text: child.textField,
            childItems: child.navigationChildItems?.map(
              (subChild) => subChild.link
            ),
          };
          return childNavItem;
        }),
        ctaButton: item.ctaButton,
      };
      return navItem;
    });


    const globalSettings = data.umbracoSitewideBannerType;
    const bannerSettings = pageSettings?.enabled ? pageSettings : globalSettings;

  return (
    <HeaderComponent
      navItems={hideNavigation ? [] : navItems}
      ctaButton={hideNavigation ? null : ctaButton}
      bannerSettings={bannerSettings}
    />
  );
};

export default Header;
